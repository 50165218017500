@import '~factor/src/scss/variables';

body {

  .date-picker {
    width: 100%;

    &._opened {

      &::after {
        z-index: 21;
      }
    }

    &._error {
      border-color: $persimmon;
    }
  }

  .date-range-picker {
    width: 100%;
    display: flex;

    &__wrapper {
      width: 100%;

      &-inner {
        z-index: 20;
      }
    }
  }
}
