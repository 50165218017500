@import '~factor/src/scss/elevation/mdc-elevation';

@mixin ripple-hover($color) {

  &._ripple-hover {

    &::before {
      content: '';
      position: absolute;
      top: -3/16 + em;
      left: -3/16 + em;
      right: -3/16 + em;
      bottom: -3/16 + em;
      border-radius: 3/16 + em;
      border: 1/16 + em solid $color;
      pointer-events: none;
      animation: hover-ripples 1s forwards;
    }
  }
}

@keyframes hover-ripples {

  0% {
    top: -3/16 + em;
    left: -3/16 + em;
    right: -3/16 + em;
    bottom: -3/16 + em;
    opacity: 1;
  }

  100% {
    top: -13/16 + em;
    left: -13/16 + em;
    right: -13/16 + em;
    bottom: -13/16 + em;
    opacity: 0;
  }
}

@mixin hover($background: transparent, $color: #fff) {

  &:not([disabled]) {

    &:hover {
      @include ripple-hover($color);
      border-color: $color;
      background: $background;
      color: $color;
      @content;
    }
  }
}

@mixin transparent($color: #000, $color-hover: #000) {

  &:not(._filled) {
    border: 1/16 + em solid $color;
    background: transparent;
    color: $color;

    @include hover($color: $color-hover) {
      @extend %mdc-elevation--z4;

      &:active {
        @extend %mdc-elevation--z8;
      }
    }
  }
}

@mixin filled($background, $background-hover) {

  &._filled {
    @extend %mdc-elevation--z2;
    border: 0;
    background: $background;
    color: #fff;

    @include hover($background: $background-hover) {
      @extend %mdc-elevation--z4;

      &:active {
        @extend %mdc-elevation--z8;
      }
    }
  }
}

.btn-close {
  position: relative;
  margin: 0;
  width: 14/16 + rem;
  height: 14/16 + rem;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20/16 + rem;
    height: 2/16 + rem;
    border-radius: 1/16 + rem;
    background: $gray;
    transition: background-color .25s;
  }

  &::before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &::after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}

.btn-round,
.btn-square {

  &._icon-right & {

    &__prefix {
      left: auto;
      right: 10/16 + rem;
    }
  }
}

.btn-round {

  &._gray {
    @include transparent($color: $dove-gray, $color-hover: $dove-gray);
    @include filled($background: $dove-gray, $background-hover: $dove-gray);
  }
}
